import { urlFilter, filterUtils } from 'o365-filterobject';
import { getMappedId } from 'status.vue.components.FilterAppIdMappings.ts';

export type EncodedFilter = {
    "gridColumn": string,
    "value": string,
    "operator": string
}

export type UrlParameters = {
    "name": string,
    "value": string,
    "useFreeText": boolean
}

export type CustomUrl = {
    "enabled": boolean
    "appId": string | null,
    "dataObject": string | null,
    "filterString": string | null,
    "encodedFilters": EncodedFilter[] | null,
    "urlParameters": UrlParameters[] | null
}

/**
 * Returns url that contains url params and encoded datasource filter.
 * If either appId or dataObject is not provided returns empty string.
 * 
 * @param {CustomUrl} customUrl - The custom URL config object.
 * @param {any} foundItem - Object that will be used to replace values.
 * @returns {string} Builded url string or if failed empty string.
 */
export async function buildUrl(customUrl: CustomUrl, foundItem: any): Promise<string> {
    if (!customUrl.appId || !customUrl.dataObject) {
        return "";
    }

    let currentFilterString = customUrl.filterString ?? "";

    if (customUrl.encodedFilters?.length) {
        const filterObjects = customUrl.encodedFilters.map(encodedFilter => ({
            column: encodedFilter.gridColumn,
            operator: encodedFilter.operator,
            value: foundItem[encodedFilter.value],
            valueType: typeof(foundItem[encodedFilter.value])
        }));

        const newDynamicFilterString = filterObjects
            .map(filter => filterUtils.filterItemToString(filter))
            .join(" AND ");

        if (!currentFilterString) {
            currentFilterString = newDynamicFilterString;
        } else if (currentFilterString.startsWith("(") && currentFilterString.endsWith(")")) {
            currentFilterString = currentFilterString.slice(0, -1) + " AND " + newDynamicFilterString + ")";
        } else {
            currentFilterString = currentFilterString + " AND " + newDynamicFilterString;
        }
    }

    let compressedFilterString = "";
    if (currentFilterString) {
        compressedFilterString = await urlFilter.getCompressedUrlFilterString(
            currentFilterString,
            getMappedId(customUrl.appId),
            customUrl.dataObject
        );
    }

    let urlParams = "";
    if (customUrl.urlParameters?.length) {
        urlParams = customUrl.urlParameters
            .map(param => {
                const value = param.useFreeText ? param.value : foundItem[param.value];
                return `${encodeURIComponent(param.name)}=${encodeURIComponent(value)}`;
            })
            .join("&");
    }

    const queryParts: string[] = [];
    if (urlParams) {
        queryParts.push(urlParams);
    }

    if (compressedFilterString) {
        queryParts.push(compressedFilterString);
    }

    let url = "/" + customUrl.appId;
    if (queryParts.length > 0) {
        url += "?" + queryParts.join("&");
    }

    return window.location.origin + url;
}